import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss']
})
export class ResultsComponent implements OnInit {
  results:Array<any> = [];
  constructor() { }

  ngOnInit(): void {
    let scores = localStorage.getItem('result');
    if(scores && scores.length){
      this.results = JSON.parse(scores);
    }
  }
  clearResults(){
    localStorage.removeItem('result');
    this.results = [];
  }
}

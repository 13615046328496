import { Component } from '@angular/core';
import 'jquery';
import 'popper.js';
import 'bootstrap';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'PriceGame';
}

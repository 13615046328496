import { Component, OnInit, AfterViewChecked } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import * as $ from 'jquery';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-price-game',
  templateUrl: './price-game.component.html',
  styleUrls: ['./price-game.component.scss']
})
export class PriceGameComponent implements OnInit, AfterViewChecked {
  responseLoaded = false;
  score = 4;
  reducedScore = 0;
  ProductList = [];
  formSubmitted = false;
  isResult = '';
  resultData = [];
  PriceGameForm: any;
  ValidationErrors = [];
  enteredPrice = 0;
  differencePrice = 0;
  actualPrice = 0;
  differencePriceString = '';
  constructor(private http: HttpClient, private formBuilder: FormBuilder, private route: ActivatedRoute, private router: Router) {
    this.route.params.subscribe(params => { this.isResult = params['result'] });
  }
  getProductList(type) {
    if (type === 'replay') {
      let name = this.PriceGameForm.get('name').value;
      let email = this.PriceGameForm.get('email').value;
      this.PriceGameForm = this.formBuilder.group({
        name: new FormControl(name, [Validators.required, Validators.pattern('[a-zA-Z ]{3,}')]),
        email: new FormControl(email, [Validators.required, Validators.pattern('[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})')])
      });
    } else {
      this.PriceGameForm = this.formBuilder.group({
        name: new FormControl(null, [Validators.required, Validators.pattern('[a-zA-Z ]{3,}')]),
        email: new FormControl(null, [Validators.required, Validators.pattern('[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})')])
      });
    }
    this.http.get('assets/game/products.json').subscribe(
      (res:any) => {
        const shuffled = res.sort(() => 0.5 - Math.random());
        let list = shuffled.slice(0, 5);
        list.forEach((product) => {
          let control = new FormControl(null, [Validators.required, Validators.pattern('^(([0-9]+(?:\.[0-9]+)?)|([0-9]*(?:\.[0-9]+)?))$')]);
          this.PriceGameForm.addControl(product._id, control);
        });
        this.ProductList = list;
        this.responseLoaded = true;
      }
    );
  }
  PlayGame() {
    this.enteredPrice = 0;
    this.actualPrice = 0;
    this.differencePrice = 0;
    this.score = 0;
    this.formSubmitted = true;
    const products = [];
    const prices = [];
    const formData = new FormData();
    this.ValidationErrors = [];
    for (let control in this.PriceGameForm['controls']) {
      let ctrl = this.PriceGameForm.get(control);
      if (!ctrl['valid']) {
        if (ctrl['errors'].required) {
          const obj = {
            'MandatoryFields': true
          }
          this.ValidationErrors.push(obj);
        } else if (ctrl['errors'].pattern) {
          if (control === 'name') {
            const obj = {
              'InvalidName': true
            }
            this.ValidationErrors.push(obj);
          } else if (control === 'email') {
            const obj = {
              'InvalidEmail': true
            }
            this.ValidationErrors.push(obj);
          } else {
            const obj = {
              'InvalidPrice': true
            }
            this.ValidationErrors.push(obj);
          }
        }
        $('#errorModal').modal('show');
        setTimeout(() => {
          $('#errorModal').modal('hide');
        }, 3000);
        return;
      } else {
        if (control === 'name' || control === 'email') {
          formData.append(control, this.PriceGameForm.get(control).value);
        } else {
          products.push(control);
          prices.push((this.PriceGameForm.get(control).value).toString());
          const a = this.ProductList.filter(x => x._id === control)[0];
          if (a.price === this.PriceGameForm.get(control).value) {
            this.score++;
          }
          this.enteredPrice = this.enteredPrice + this.PriceGameForm.get(control).value;
        }
      }
    }
    this.ProductList.forEach(product => {
      this.actualPrice = this.actualPrice + product.price;
    });
    this.differencePrice = Math.abs(this.actualPrice - this.enteredPrice);
    this.differencePriceString = this.differencePrice.toFixed(2);
    this.reducedScore = 5 - this.score;

    let scores:any = localStorage.getItem('result');
    if(scores && scores.length){
      scores = JSON.parse(scores);
      scores.push({
        name: this.PriceGameForm.get('name').value,
        email: this.PriceGameForm.get('email').value,
        score: this.score
      });
    } else {
      scores = [{
        name: this.PriceGameForm.get('name').value,
        email: this.PriceGameForm.get('email').value,
        score: this.score
      }];
    }
    localStorage.setItem('result', JSON.stringify(scores));
    $('#scoreModal').modal('show');
  }
  ReplayGame() {
    $('#scoreModal').modal('hide');
    this.getProductList('replay');
  }
  closeModal() {
    $('#scoreModal').modal('hide');
    this.PriceGameForm.reset();
    // this.router.navigate(['/']);
  }
  getResult() {
    // this.http.get(environment.ResponseUrl + 'game/list').subscribe(
    //   res => {
    //     if (res['status'] === 200) {
    //       this.resultData = res['responseData'];
    //     }
    //   }
    // );
  }
  ngOnInit() {
    if (this.isResult !== 'result') {
      this.getProductList('new');
    } else if (this.isResult === 'result') {
      this.getResult();
    }
  }
  ngAfterViewChecked() {
    $('[data-toggle="tooltip"]').tooltip();
  }

}
